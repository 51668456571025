import { menuItems } from './menu';

export function parseMenuStructure() {
  const menuStructure = [];
  
  menuItems.forEach(item => {
    if (item.isTitle) {
      // This is a group title
      menuStructure.push({
        id: item.groupe,
        label: item.label,
        items: []
      });
    } else if (item.groupe) {
      // This is a menu item
      const group = menuStructure.find(g => g.id === item.groupe);
      if (group) {
        group.items.push({
          id: item.id,
          label: item.label,
          icon: item.icon,
          link: item.link
        });
      } else {
        console.warn(`Group ${item.groupe} not found for item ${item.label}`);
      }
    }
  });

  return menuStructure;
}