<template>
  <div class="rights-assignment">
    <div>
    <input type="checkbox" :id="'all'" v-model="allRight" :value="0"
          @change="toggleAll">
        <label :for="'all'">Tous les droits</label>
  </div>
    <div v-for="group in menuStructure" :key="group.id" class="group">
      <div class="group-header" style="color: #3aaa35;">
        <input type="checkbox" class="mr-2" :id="'group-' + group.id" v-model="selectedGroups" :value="group.id"
          @change="toggleGroup(group.id)">
        <label :for="'group-' + group.id">{{ group.label }}</label>
      </div>
      
      <div class="group-items row" v-if="!selectedGroups.includes(group.id)">
        <div class="col-lg-3" v-for="item in group.items" :key="item.id" >
         
          <div class="item d-flex align-items-start mr-3 "  style="font-weight: normal !important;">
            <input type="checkbox" class="mr-2" :id="'item-' + item.id" v-model="selectedItems" :value="item.id">
            <label :for="'item-' + item.id">{{ item.label }}</label>
          </div>
           
        </div>
      </div>
      <hr class="text-primary" />
    </div>
  </div>
</template>

<script>
import { parseMenuStructure } from '../../../components/menuParser';

export default {
  data() {
    return {
      menuStructure: [],
      selectedGroups: [],
      selectedItems: [],
      allRight: false,
    };
  },
  mounted() {
    this.menuStructure = parseMenuStructure();
  },
  watch: {
    selectedGroups(newValue) {
      if (newValue.length > 0) {
        this.allRight = false;
      }
    },
    selectedItems(newValue) {
      if (newValue.length > 0) {
        this.allRight = false;
      }
    },
    
  },
  methods: {

    toggleAll() {
      if (this.allRight) {
        this.selectedGroups = [];
        this.selectedItems = [];
      }
    },
   
    toggleGroup(groupId) {
      if (this.allRight) {
        this.allRight = false;
      }
      const group = this.menuStructure.find(g => g.id === groupId);
      if (this.selectedGroups.includes(groupId)) {
        // If group is selected, remove all its items from selectedItems
        this.selectedItems = this.selectedItems.filter(id =>
          !group.items.some(item => item.id === id)
        );
      }
    },
    getGroupRights(groupId) {
      return this.selectedGroups.includes(groupId);
    },
    getItemRights(itemId) {
      return this.selectedItems.includes(itemId);
    },
    getSelectedRights() {
      if (this.allRight) {
        return [0];  // Return [0] if allRight is selected
      }
      const rights = [...this.selectedItems];
      this.selectedGroups.forEach(groupId => {
        const group = this.menuStructure.find(g => g.id === groupId);
        rights.push(...group.items.map(item => item.id));
      });
      return [...new Set(rights)]; // Remove duplicates
    }
  }
};
</script>

<style>
.rights-assignment {
  font-family: Arial, sans-serif;
}

.group {
  margin-bottom: 10px;
}

.group-header {
  color:#3AAA35;
  font-weight: bold;
  font-size: larger;
}

.group-items {
  margin-left: 20px;
  font-size: 1em;
  font-family:'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}

/*.item {
  margin-top: 5px;
}*/
</style>