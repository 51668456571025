<script>
//import router from "@/router/index.js";
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { apiRequest } from "@/helpers/api-call";
import Multiselect from "vue-multiselect";
import RightsAssignment from './RightsAssignment.vue';
import { parseMenuStructure } from '../../../components/menuParser';

export default {
  page: {
    title: "Administration",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, Multiselect, RightsAssignment },
  data() {
    return {
      title: "Administrateur",
      items: [
        {
          text: "Dashboard",
          href: "/",
        },
        {
          text: "Historistique",
          active: true,
        },
      ],
      menuStructure: [],
      order: [],
      ordersData: [],
      adminData: [],
      activityData: [],
      thenDay: "",
      showAll: false,
      users: [],
      rowCall: null,
      value: null,
      nbrAdmin: 0,
      dataUser: [],
      id: 0,
      totalRows: 1,
      currentPage: 1,
      perPage: 100,
      pageOptions: [100, 250, 500],
      filter: null,
      filterOn: [],
      sortBy: "orderid",
      sortDesc: false,
      fields: [
        { key: "trajet", sortable: true, label: "Trajet" },
        { key: "conducteur", sortable: true, label: "Conducteur" },
        { key: "place", sortable: true, label: "Places" },
        { key: "date", sortable: true, label: "Départ" },
        { key: "total", sortable: true, label: "Montant" },
        { key: "action" },
      ],
      field: [
        { key: "userid", sortable: true, label: "Nom & Prénom" },
        { key: "total", sortable: true, label: "Montant viré" },
        { key: "date", sortable: true, label: "Effectué le" },
      ],

      fieldsCommercial: [
        { key: "agent", sortable: true, label: "Agent" },
        { key: "codeparrainage", sortable: true, label: "Code Parrainage" },
        { key: "status", sortable: true, label: "Statut" },
        { key: "dateAcces", sortable: true, label: "Date création" },

        { key: "action" },
      ],
      admin: [
        { key: "firstname", sortable: true, label: "Nom & Prénom" },
        { key: "date", sortable: true, label: "Date de création" },
        { key: "etat", sortable: true, label: "Etat du compte" },
        { key: "action" },
      ],
      activity: [
        { key: "admin", sortable: true },
        { key: "details", sortable: true },
        { key: "date", sortable: true },
      ],
      submitted: false,
      submitform: false,
      submit: false,
      typesubmit: false,
      showDelete: false,
      showEdit: false,
      showhable: false,
      toutDroits: false,
      trajetDroits: null,
      donsDroits: null,
      bonsDroits: null,
      messageDroits: null,
      reserveDroits: null,
      promoDroits: null,
      dashDroits: null,
      transactionsDroits: null,
      adminDroits: null,
      utilisateurDroits: null,
      alertesDroits: null,
      gainsDroits: null,
      virementDroits: null,
      accesTable: [],
      dateJournal: null,
      demandesDroits: null,
      agencesDroits: null,
      abonnementsDroits: null,
      adminDroitsLocation: null,
      transactionDroits: null,
      transactionDroitsLocation: null,
      assuranceDroit: null,
      nsiaDroit: null,
      startValue: 1,
      startValueAll: 1,
      endValueAll: 500,
      totalValueAll: "0",
      endValue: 500,
      totalValue: "0",
      userChargeLoader: false,
      newActivityData: [],
      recherche: "",
      totalActivity: 0,
      newAdminData: [],

      libelLoader: "Chargement en cours...",
      myUsersToChoose: [],
      searchUser: "",
      actualiteDroits: null,
      informationsDroits: null,
      discussionsDroits: null,
      conseilPassagerDroits: null,
      showdisable: false,
      listDroit: [],
      showDroitsList: false,
      headerBgVariant: "light",
      headerTextVariant: "light",
      droitChoice: [],

      showAddCommercial: false,
      listeAgent: []
    };
  },

  async mounted() {
    this.menuStructure = parseMenuStructure();
    //Chargement des données
    this.init();
    this.agentCommercial();
  },

  methods: {
    async init() {
      this.adminf();

      const data = await apiRequest(
        "GET",
        "admin/users?page=" + 10400 + "&limit=" + 11100,
        undefined,
        false
      );
      if (data && data.data) {
        const formattedTable = data.data.map((user) => {
          return {
            id: user.id,
            name: user.lastname + " " + user.firstname,
          };
        });
        this.users = formattedTable;
      }
    },

    async agentCommercial() {


      const data = await apiRequest(
        "GET",
        "commercials",
        undefined,
        false
      );
      if (data && data.data) {
        // console.log('commerciaux',data.data);

        const formattedTable = data.data.map((user) => {
          return {
            id: user.id,
            userId: user.agent.id,
            codeparrainage: user.codeparrainage,
            status: user.active,
            dateAcces: user.dateAcces,

            agent: user.agent.personne.firstname + " " + user.agent.personne.lastname,
          };
        });
        this.listeAgent = formattedTable;

      }
    },

    async adminf() {
      const adminsTable = await apiRequest(
        "GET",
        "admin/admins",
        undefined,
        false
      );
      if (adminsTable && adminsTable.data) {
        console.log('adminsTable.data', adminsTable.data);
        const formattedTable = adminsTable.data.map((user) => {
          return {
            id: user.id,
            firstname: user.firstname + ' ' + user.lastname,
            date: new Date(user.dateCreation).toLocaleString("fr-FR"),
            etat: user.active ? "Actif" : "Suspendu",
            niveau: user.niveau,
          };
        });
        this.adminData = formattedTable;
        this.nbrAdmin = this.adminData.length;
        this.totalValue = this.adminData.length;
      }
    },
    // eslint-disable-next-line no-unused-vars
    formSubmit(e) {
      this.submitted = true;
      // stop here if form is invalid
      this.$v.$touch();
      //this.create();
    },
    async submitAdminAdd() {
      const accessRights = this.$refs.rightsAssignment.getSelectedRights();
      console.log('accessRights', accessRights);

      const returnAddSucess = await apiRequest(
        "POST",
        "admin/set-admin",
        {
          idUser: this.value.id,
          active: true,
          acces: accessRights,
        },
        false
      );
      if (returnAddSucess && returnAddSucess.data) {
        this.$root.$emit('user-rights-updated');
        this.$toast.success("Admin ajouté avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });

        this.init();
        this.resetForm();

        (this.toutDroits = false),
          (this.trajetDroits = null),
          (this.donsDroits = null),
          (this.bonsDroits = null),
          (this.messageDroits = null),
          (this.this.reserveDroits = null),
          (this.transactionsDroits = null),
          (this.adminDroits = null),
          (this.utilisateurDroits = null),
          (this.alertesDroits = null),
          (this.gainsDroits = null),
          (this.virementDroits = null),
          (this.promoDroits = null),
          (this.assuranceDroit = null),
          (this.nsiaDroit = null),
          (this.accesTable = []),
          (this.actualiteDroits = null),
          (this.informationsDroits = null),
          (this.conseilConducteurDroits = null),
          (this.conseilPassagerDroits = null);
        this.discussionsDroits = null;
      }
    },

    resetForm() {
      this.$refs.rightsAssignment.selectedGroups = [];
      this.$refs.rightsAssignment.selectedItems = [];
      // ... reset other fields as necessary ...
    },
    desactivity(row) {
      this.showdisable = true;
      if (row.userId) {
        this.rowCall = row.userId;

      } else {
        this.rowCall = row.id;
      }


      console.log(' this.rowCall', row);
    },
    desactivityAgent(row) {
      console.log();
      this.showdisable = true;
      this.rowCall = row.id;
    },

    emptyAll() {
      this.listDroit = []
    },


    async desactiveUser() {
      this.showdisable = false;
      const returnAddSucess = await apiRequest(
        "POST",
        "admin/set-admin",
        {
          idUser: this.rowCall,
          active: false,
          acces: [],
        },
        false
      );
      if (returnAddSucess && returnAddSucess.data) {
        console.log(returnAddSucess);
        this.$toast.success("Admin désactiver avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });

        this.init();
      }
    },
    chargerDroit(row) {
      this.emptyAll();
      console.log('row', row, 'this.adminData', this.adminData);
      console.log("lthis.menuStructure", this.menuStructure);
      if (row.niveau != undefined && row.niveau.length == 1) {
        this.menuStructure.forEach(item => this.listDroit.push(item.label));
        this.showDroitsList = true;
        console.log("list droit", this.listDroit);
      } else {
        this.showDroitsList = true;
        row.niveau.forEach((element) => {
          console.log("les niveaux ", element);
          this.menuStructure.forEach(item => {
            item.items.forEach(data => {
              if (data.id == element) {
                this.listDroit.push(data.label)
              }

            });
          }
          );
        });

      }
    },
    tooltipForm() {
      this.submitform = true;
      //this.submitted = true;
      this.formSubmit();
      this.$v.$touch();
    },

    onFiltered(filteredItems) {
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },

    // Ajout d'agent commercial

    addCommercialConfirm() {
      this.showAddCommercial = true;
    },

    async submitCommercialAdd() {
      this.showdisable = false;
      const returnAddCommercial = await apiRequest(
        "POST",
        "commercial/add",
        {
          agent: this.value.id,
        },
        false
      );
      if (returnAddCommercial && returnAddCommercial.data) {
        this.showAddCommercial = false;
        this.$toast.success("Agent commercial ajouté avec succès", {
          position: "bottom-right",
          timeout: 2000,
          closeOnClick: true,
          pauseOnFocusLoss: true,
          pauseOnHover: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });

        this.init();
      }
    },

    /**
     * Range validation form submit
     */
    // eslint-disable-next-line no-unused-vars
    rangeform(e) {
      this.submit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    typeForm(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
    },

    async search() {
      if (this.dateJournal != null) {
        const data = await apiRequest(
          "GET",
          "admin/journal?all=" + false + "&date=" + this.dateJournal,
          undefined,
          false
        );
        if (data && data.data) {
          //console.log("data.data:", data.data);
          const formattedTable = data.data.map((journal) => {
            return {
              admin: journal.admin.lastname + " " + journal.admin.firstname,
              details: journal.text,
              date: journal.dateHeure,
            };
          });
          this.activityData = formattedTable;
          //console.log('this.users:', this.users)
        }
      }
    },
    async all() {
      const data = await apiRequest(
        "GET",
        "admin/journal?all=" + true + "&date=" + this.dateJournal,
        undefined,
        false
      );
      if (data && data.data) {
        const formattedTable = data.data.map((journal) => {
          return {
            admin: journal.admin.lastname + " " + journal.admin.firstname,
            details: journal.text,
            date: journal.dateHeure,
          };
        });
        this.activityData = formattedTable;
        this.totalActivity = this.activityData.length;
      }
    },

    demandechange() {
      if (this.demandesDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 13) {
              this.accesTable.splice(i, 13);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 13) {
              console.log("");
            } else {
              this.accesTable.push(13);
              break;
            }
          }
        } else {
          this.accesTable.push(13);
        }
      }
      console.log(this.accesTable);
    },
    agenceschange() {
      if (this.agencesDroits == true) {
        this.toutDroits = false;

        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 14) {
              this.accesTable.splice(i, 14);
              console.log("suprression");
              break;
            }
          }
          console.log("taille plus grand que 0");
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 14) {
              console.log("Danger");
            } else {
              this.accesTable.push(14);
              console.log("Danger");
              break;
            }
          }
        } else {
          this.accesTable.push(14);
        }
      }
    },
    abonnementchange() {
      if (this.abonnementsDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 15) {
              this.accesTable.splice(i, 15);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 15) {
              console.log("");
            } else {
              this.accesTable.push(15);
              break;
            }
          }
        } else {
          this.accesTable.push(15);
        }
      }
    },
    adminLocationchange() {
      if (this.adminDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 13) {
              this.accesTable.splice(i, 13);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 13) {
              console.log("");
            } else {
              this.accesTable.push(13);
              break;
            }
          }
        } else {
          this.accesTable.push(13);
        }
      }
    },
    dashChange() {
      if (this.dashDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 17) {
              this.accesTable.splice(i, 17);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 17) {
              console.log("");
            } else {
              this.accesTable.push(17);
              break;
            }
          }
        } else {
          this.accesTable.push(17);
        }
      }
    },
    discussionsChange() {
      if (this.discussionDroits == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 16) {
              this.accesTable.splice(i, 16);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 16) {
              console.log("");
            } else {
              this.accesTable.push(16);
              break;
            }
          }
        } else {
          this.accesTable.push(16);
        }
      }
      console.log(this.accesTable);
    },

    /*    transactionschangeLocation() {
      if (this.transactionDroitsLocation == true) {
        this.toutDroits = false;
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 16) {
              this.accesTable.splice(i, 16);
              break;
            }
          }
        }
      } else {
        if (this.accesTable.length != 0) {
          for (let i = 0; i < this.accesTable.length; i++) {
            if (this.accesTable[i] == 16) {
              console.log("");
            } else {
              this.accesTable.push(16);
              break;
            }
          }
        } else {
          this.accesTable.push(16);
        }
      }
      console.log(this.accesTable);
    }, */

    async actif(page, limit) {
      //Chargement des données

      const listeWallet = await apiRequest(
        "GET",
        "admin/users?page=" + page + "&limit=" + limit,
        undefined,
        false
      );

      if (listeWallet && listeWallet.data) {
        const formattedTable = listeWallet.data.map((user) => {
          return {
            id: user.id,
            firstname: user.firstname,
            date: new Date(user.dateCreation).toLocaleString("fr-FR"),
            etat: user.active ? "Actif" : "Suspendu",
          };
        });
        this.newAdminData = formattedTable;
        this.userChargeLoader = false;
      }
    },
    async actifAll(page, limit) {
      //Chargement des données

      const listeWallet = await apiRequest(
        "GET",
        "admin/journal?all=" +
        true +
        "&date=" +
        this.dateJournal +
        "?page=" +
        page +
        "&limit=" +
        limit,

        undefined,
        false
      );

      if (listeWallet && listeWallet.data) {
        const formattedTable = listeWallet.data.map((journal) => {
          return {
            admin: journal.admin.lastname + " " + journal.admin.firstname,
            details: journal.text,
            date: journal.dateHeure,
          };
        });
        this.newActivityData = formattedTable;
        this.userChargeLoader = false;
      }
    },

    async userTotal() {
      const datatotal = await apiRequest(
        "GET",
        "admin/user-total",
        undefined,
        false
      );
      if (datatotal && datatotal.data) {
        this.totalValue = datatotal.data.total;
      }
    },

    async userSearchActif() {
      console.log(this.filter);
      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "GET",
        "admin/users?search=" + this.filter,
        undefined,

        false
      );
      if (dataSearch && dataSearch.data) {
        const formattedTable = dataSearch.data.map((user) => {
          return {
            id: user.id,
            firstname: user.firstname,
            date: new Date(user.dateCreation).toLocaleString("fr-FR"),
            etat: user.active ? "Actif" : "Suspendu",
          };
        });
        this.newAdminData = formattedTable;
        this.userChargeLoader = false;
      }
    },

    decrement() {
      this.userChargeLoader = true;

      this.startValue -= 500;
      this.endValue -= 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    decrementAll() {
      this.userChargeLoader = true;

      this.startValueAll -= 500;
      this.endValueAll -= 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValueAll, this.endValueAll);
    },

    increment() {
      this.userChargeLoader = true;
      this.startValue += 500;
      this.endValue += 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    incrementAll() {
      this.userChargeLoader = true;
      this.startValueAll += 500;
      this.endValueAll += 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actifAll(this.startValueAll, this.endValueAll);
    },
    incrementToLast() {
      this.userChargeLoader = true;
      this.startValue = this.totalValue - 500;
      this.endValue = this.totalValue;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actifAll(this.startValue, this.endValue);
    },
    incrementToLastAll() {
      this.userChargeLoader = true;
      this.startValueAll = this.totalValueAll - 500;
      this.endValueAll = this.totalValueAll;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actifAll(this.startValueAll, this.endValueAll);
    },
    firstVal() {
      this.userChargeLoader = true;
      this.startValue = 1;
      this.endValue = 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actif(this.startValue, this.endValue);
    },
    firstValAll() {
      this.userChargeLoader = true;
      this.startValueAll = 1;
      this.endValueAll = 500;
      console.log("userChargeLoader", this.userChargeLoader);
      this.actifAll(this.startValueAll, this.endValueAll);
    },
    async userListChoice() {
      //Chargement des données

      this.userChargeLoader = true;
      const dataSearch = await apiRequest(
        "POST",
        "admin/user-recherche",

        { mots: this.searchUser },
        false
      );
      if (dataSearch && dataSearch.data) {
        const formattedTable = dataSearch.data.map((user) => {
          return {
            id: user.id,
            name: user.lastname + " " + user.firstname,
          };
        });
        this.myUsersToChoose = formattedTable;
        this.userChargeLoader = false;
      }
    },




    openNewTabExperience(row) {
      window.open(`${window.location.origin}/experience/user_experience/${row.codeparrainage}`, '_blank');
    },


  },
  watch: {
    accesTable(newValue, oldValue) {
      console.log(oldValue);
      console.log(JSON.stringify(newValue));
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="d-flex align-items-center otherThing" v-if="userChargeLoader === true">
        <span class="loading">Chargement en cours...</span>
        <div aria-hidden="true"></div>
      </div>
    </div>

    <div class="row">
      <div class="col-lg-8">

      </div>

    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="card backCard">
          <div class="card-body">
            <div class="card-title">
              <h5>Utilisateur</h5>
              <div class="inputCol p-3">
                <div class="input">
                  <input type="search" id="serchInput" placeholder="Rechercher un utilisateur" v-model="searchUser"
                    @keyup.enter="userListChoice" />
                </div>
              </div>
              <div>
                <div class="form-group">
                  <label class="control-label">Choisir l'utilisateur :</label>
                  <multiselect v-model="value" :options="myUsersToChoose" label="name" track-by="name"></multiselect>
                </div>
                <div class="mt-5 btn-flexible">
                  <button class="btn btn-commercial float-right" v-on:click="addCommercialConfirm()">
                    AJOUTER COMMERCIAL
                  </button>
                  <button class="btn btn-rmobility float-right ml-2" v-on:click="submitAdminAdd()">
                    AJOUTER ADMIN
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-8">
        <div class="card">
          <div class="card-body" style="max-height: 500px; overflow: auto">
            <div class="card-title">
              <form @submit.prevent="submitAdminAdd">
                <h6 class="mt-4">Ajouter des accès</h6>
                <hr />
                <RightsAssignment ref="rightsAssignment" />



              </form>
            </div>
          </div>
        </div>
      </div>

      <div class="col-md-12">
        <div class="card">
          <div class="card-body mt-3">
            <b-tabs pills justified content-class="p-3 text-muted">
              <b-tab active class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"><i class="mdi mdi-clipboard-account mr-2"></i>Gérer les
                    administrateurs</span>
                </template>
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="col-md-12">
                        <b-card header-class="bg-transparent border-primary" class="border border-primary">
                          <template v-slot:header>
                            <h5 class="my-0 mb-4">Nombre d'administrateur</h5>
                          </template>
                          <div class="float-right">
                            <h1 class="text-primary">{{ this.nbrAdmin }}</h1>
                          </div>
                        </b-card>
                      </div>
                    </div>
                  </div>
                  <div class="card">
                    <div class="card-body">
                      <div class="row d-flex justify-content-end p-2">
                        <transition mode="out-in">
                          <div class="dataTables_paginate paging_simple_numbers float-right">
                            <ul class="pagination pagination-rounded mb-0">
                              <!-- pagination -->
                              <li>
                                <span class="interval"> {{ startValue }}</span>
                                -
                                <span class="interval">{{ endValue }}</span> sur
                                {{ totalValue }}
                              </li>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <li>
                                <button class="myBouton" @click="firstVal()" :disabled="startValue == 1"
                                  data-toggle="tooltip" data-placement="bottom" title="Prem">
                                  <ion-icon name="play-skip-back-outline"></ion-icon>
                                </button>
                                <button type="button" class="myBouton" @click="decrement()" :disabled="endValue == 500"
                                  data-toggle="tooltip" data-placement="bottom" title="Préc">
                                  <ion-icon name="chevron-back-outline"></ion-icon>
                                </button>
                                <button class="myBouton" @click="increment()" data-toggle="tooltip"
                                  data-placement="bottom" title="Suiv" :disabled="endValue >= totalValue">
                                  <ion-icon name="chevron-forward-outline"></ion-icon>
                                </button>
                                <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip"
                                  data-placement="bottom" title="Dern" :disabled="endValue >= totalValue">
                                  <ion-icon name="play-skip-forward-outline"></ion-icon>
                                </button>
                              </li>
                            </ul>
                          </div>
                        </transition>
                      </div>
                      <div class="col-md-12">
                        <!--  <span class="" style="font-weight: bold; color: black"
                          >Liste des administrateurs</span
                        > -->

                        <b-tabs nav-class="nav-tabs-custom">
                          <b-tab title-link-class="p-3">
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                              </span>
                              <span class="d-none d-sm-inline-block"><i class="mdi mdi-clipboard-account mr-2"></i>Liste
                                des administrateurs</span>
                            </template>
                            <div class="row d-flex justify-content-between align-items-center mt-4">
                              <div class="p-3 h-10"></div>
                              <!-- Search -->
                              <div class="inputCol p-3">
                                <div class="input">
                                  <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filter"
                                    @keyup.enter="userSearchActif" />
                                </div>
                              </div>
                              <!-- End search -->
                            </div>

                            <div class="table-responsive">
                              <b-table class="table-centered" :items="newAdminData.length != 0
                                ? newAdminData
                                : adminData
                                " :fields="admin" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                                :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                                @filtered="onFiltered"><template v-slot:cell(etat)="row">
                                  <div class="badge font-size-12" :class="{
                                    'badge-soft-danger':
                                      `${row.value}` === 'Suspendu',
                                    'badge-soft-success':
                                      `${row.value}` === 'Actif',
                                  }">
                                    {{ row.value }}
                                  </div>
                                </template>
                                <template v-slot:cell(invoice)>
                                  <button class="btn btn-light btn-rounded">
                                    Invoice
                                    <i class="mdi mdi-download ml-2"></i>
                                  </button>
                                </template>
                                <template v-slot:cell(action)="row">
                                  <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                                    @click="desactivity(row.item)" title="Désactiver">
                                    <i class="mdi mdi-close font-size-18"></i>
                                  </a>

                                  <a href="javascript:void(0);" class="text-primary ml-3" v-b-tooltip.hover
                                    @click="chargerDroit(row.item)" title="Voir les droits">
                                    <i class="mdi mdi-eye font-size-18"></i>
                                  </a>
                                </template>
                              </b-table>
                            </div>
                            <div class="row">
                              <div class="col">

                                <transition mode="out-in">
                                  <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                      <!-- pagination -->
                                      <li>
                                        <span class="interval">
                                          {{ startValue }}</span>
                                        -
                                        <span class="interval">{{
                                          endValue
                                          }}</span>
                                        sur
                                        {{ totalValue }}
                                      </li>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <li>
                                        <button class="myBouton" @click="firstVal()" :disabled="startValue == 1"
                                          data-toggle="tooltip" data-placement="bottom" title="Prem">
                                          <ion-icon name="play-skip-back-outline"></ion-icon>
                                        </button>
                                        <button type="button" class="myBouton" @click="decrement()"
                                          :disabled="endValue == 500" data-toggle="tooltip" data-placement="bottom"
                                          title="Préc">
                                          <ion-icon name="chevron-back-outline"></ion-icon>
                                        </button>
                                        <button class="myBouton" @click="increment()" data-toggle="tooltip"
                                          data-placement="bottom" title="Suiv" :disabled="endValue >= totalValue">
                                          <ion-icon name="chevron-forward-outline"></ion-icon>
                                        </button>
                                        <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip"
                                          data-placement="bottom" title="Dern" :disabled="endValue >= totalValue">
                                          <ion-icon name="play-skip-forward-outline"></ion-icon>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </transition>
                              </div>
                            </div>
                          </b-tab>

                          <b-tab title-link-class="p-3">
                            <template v-slot:title>
                              <span class="d-inline-block d-sm-none">
                                <i class="fas fa-home"></i>
                              </span>
                              <span class="d-none d-sm-inline-block"><i class="mdi mdi-clipboard-account mr-2"></i>Liste
                                des agents commerciaux</span>
                            </template>
                            <div class="row d-flex justify-content-between align-items-center mt-4">
                              <div class="p-3 h-10"></div>
                              <!-- Search -->
                              <div class="inputCol p-3">
                                <div class="input">
                                  <input type="search" id="serchInput" placeholder="Rechercher..." v-model="filter"
                                    @keyup.enter="userSearchActif" />
                                </div>
                              </div>
                              <!-- End search -->
                            </div>

                            <div class="table-responsive">
                              <b-table class="table-centered" :items="listeAgent
                                " :fields="fieldsCommercial" responsive="sm" :per-page="perPage" :current-page="currentPage"
                                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
                                :filter-included-fields="filterOn" @filtered="onFiltered"><template
                                  v-slot:cell(etat)="row">
                                  <div class="badge font-size-12" :class="{
                                    'badge-soft-danger':
                                      `${row.value}` === 'Suspendu',
                                    'badge-soft-success':
                                      `${row.value}` === 'Actif',
                                  }">
                                    {{ row.value }}
                                  </div>
                                </template>
                                <template v-slot:cell(invoice)>
                                  <button class="btn btn-light btn-rounded">
                                    Invoice
                                    <i class="mdi mdi-download ml-2"></i>
                                  </button>
                                </template>
                                <template v-slot:cell(action)="row">
                                  <a href="javascript:void(0);" class="text-danger" v-b-tooltip.hover
                                    @click="desactivity(row.item)" title="Désactiver">
                                    <i class="mdi mdi-close font-size-18"></i>
                                  </a>

                                  <a href="javascript:void(0);" class="text-primary ml-3" v-b-tooltip.hover
                                    @click="openNewTabExperience(row.item)" title="Voir les stats">
                                    <i class="mdi mdi-eye font-size-18"></i>
                                  </a>
                                </template>
                              </b-table>
                            </div>
                            <div class="row">
                              <div class="col">
                                <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                                <transition mode="out-in">
                                  <div class="dataTables_paginate paging_simple_numbers float-right">
                                    <ul class="pagination pagination-rounded mb-0">
                                      <!-- pagination -->
                                      <li>
                                        <span class="interval">
                                          {{ startValue }}</span>
                                        -
                                        <span class="interval">{{
                                          endValue
                                          }}</span>
                                        sur
                                        {{ totalValue }}
                                      </li>
                                      &nbsp;&nbsp;&nbsp;&nbsp;
                                      <li>
                                        <button class="myBouton" @click="firstVal()" :disabled="startValue == 1"
                                          data-toggle="tooltip" data-placement="bottom" title="Prem">
                                          <ion-icon name="play-skip-back-outline"></ion-icon>
                                        </button>
                                        <button type="button" class="myBouton" @click="decrement()"
                                          :disabled="endValue == 500" data-toggle="tooltip" data-placement="bottom"
                                          title="Préc">
                                          <ion-icon name="chevron-back-outline"></ion-icon>
                                        </button>
                                        <button class="myBouton" @click="increment()" data-toggle="tooltip"
                                          data-placement="bottom" title="Suiv" :disabled="endValue >= totalValue">
                                          <ion-icon name="chevron-forward-outline"></ion-icon>
                                        </button>
                                        <button class="myBouton" @click="incrementToLast()" data-toggle="tooltip"
                                          data-placement="bottom" title="Dern" :disabled="endValue >= totalValue">
                                          <ion-icon name="play-skip-forward-outline"></ion-icon>
                                        </button>
                                      </li>
                                    </ul>
                                  </div>
                                </transition>
                              </div>
                            </div>
                          </b-tab>
                        </b-tabs>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
              <b-tab class="border-0">
                <template v-slot:title>
                  <span class="d-inline-block d-sm-none">
                    <i class="fas fa-home"></i>
                  </span>
                  <span class="d-none d-sm-inline-block"><i class="mdi mdi-clipboard-account mr-2"></i>Journal des
                    activités</span>
                </template>
                <div class="col-md-12">
                  <div class="card">
                    <div class="card-body">
                      <div class="row d-flex justify-content-end p-2">
                        <transition mode="out-in">
                          <div class="dataTables_paginate paging_simple_numbers float-right">
                            <ul class="pagination pagination-rounded mb-0">
                              <!-- pagination -->
                              <li>
                                <span class="interval">
                                  {{ startValueAll }}</span>
                                -
                                <span class="interval">{{ endValueAll }}</span>
                              </li>
                              &nbsp;&nbsp;&nbsp;&nbsp;
                              <li>
                                <button class="myBouton" @click="firstValAll()" :disabled="startValueAll == 1"
                                  data-toggle="tooltip" data-placement="bottom" title="Prem">
                                  <ion-icon name="play-skip-back-outline"></ion-icon>
                                </button>
                                <button type="button" class="myBouton" @click="decrementAll()"
                                  :disabled="endValueAll == 500" data-toggle="tooltip" data-placement="bottom"
                                  title="Préc">
                                  <ion-icon name="chevron-back-outline"></ion-icon>
                                </button>
                                <button class="myBouton" @click="incrementAll()" data-toggle="tooltip"
                                  data-placement="bottom" title="Suiv">
                                  <ion-icon name="chevron-forward-outline"></ion-icon>
                                </button>
                                <button class="myBouton" @click="incrementToLastAll()" data-toggle="tooltip"
                                  data-placement="bottom" title="Dern">
                                  <ion-icon name="play-skip-forward-outline"></ion-icon>
                                </button>
                              </li>
                            </ul>
                          </div>
                        </transition>
                      </div>
                      <div class="row">
                        <div class="col-md-12">
                          <div class="row d-flex justify-content-center">
                            <input type="date" id="" class="" v-model="dateJournal" />
                            <button class="btn btn-primary ml-2" @click="search">
                              Rechercher
                            </button>
                          </div>
                        </div>
                      </div>
                      <div class="row d-flex justify-content-center">
                        <div class="col-md-12 mt-3 d-flex justify-content-center">
                          <button class="btn btn-warning" @click="all">
                            Voir tout
                          </button>
                        </div>
                      </div>
                      <div class="table-responsive mt-3">
                        <b-table class="table-centered" :items="newActivityData.length != 0
                          ? newActivityData
                          : activityData
                          " :fields="activity" responsive="sm" :per-page="perPage" :current-page="currentPage" :sort-by.sync="sortBy"
                          :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                          @filtered="onFiltered">
                          <template v-slot:cell(paymentstatus)="row">
                            <div class="badge font-size-12" :class="{
                              'badge-soft-danger':
                                `${row.value}` === 'Chargeback',
                              'badge-soft-success': `${row.value}` === 'Paid',
                              'badge-soft-warning':
                                `${row.value}` === 'Unpaid',
                            }">
                              {{ row.value }}
                            </div>
                          </template>
                          <template v-slot:cell(invoice)>
                            <button class="btn btn-light btn-rounded">
                              Invoice
                              <i class="mdi mdi-download ml-2"></i>
                            </button>
                          </template>
                          <template v-slot:cell(action)>
                            <a href="javascript:void(0);" class="btn btn-success mr-3" v-b-tooltip.hover
                              title="virement">
                              Virement
                            </a>
                          </template>
                        </b-table>
                      </div>
                      <div class="row">
                        <div class="col">
                          <!--   <div
                  class="dataTables_paginate paging_simple_numbers float-right"
                >
                  <ul class="pagination pagination-rounded mb-0">
                
                    <b-pagination
                      v-model="currentPage"
                      :total-rows="rows"
                      :per-page="perPage"
                      :aria-controls="trajet"
                    ></b-pagination>
                  </ul>
                </div> -->
                          <transition mode="out-in">
                            <div class="dataTables_paginate paging_simple_numbers float-right">
                              <ul class="pagination pagination-rounded mb-0">
                                <!-- pagination -->
                                <li>
                                  <span class="interval">
                                    {{ startValueAll }}</span>
                                  -
                                  <span class="interval">{{
                                    endValueAll
                                    }}</span>
                                </li>
                                &nbsp;&nbsp;&nbsp;&nbsp;
                                <li>
                                  <button class="myBouton" @click="firstValAll()" :disabled="endValueAll == 500"
                                    data-toggle="tooltip" data-placement="bottom" title="Prem">
                                    <ion-icon name="play-skip-back-outline"></ion-icon>
                                  </button>
                                  <button type="button" class="myBouton" @click="decrementAll()"
                                    :disabled="endValueAll == 500" data-toggle="tooltip" data-placement="bottom"
                                    title="Préc">
                                    <ion-icon name="chevron-back-outline"></ion-icon>
                                  </button>
                                  <button class="myBouton" @click="incrementAll()" data-toggle="tooltip"
                                    data-placement="bottom" title="Suiv">
                                    <ion-icon name="chevron-forward-outline"></ion-icon>
                                  </button>
                                  <button class="myBouton" @click="incrementToLastAll()" data-toggle="tooltip"
                                    data-placement="bottom" title="Dern">
                                    <ion-icon name="play-skip-forward-outline"></ion-icon>
                                  </button>
                                </li>
                              </ul>
                            </div>
                          </transition>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>

    <b-modal id="modal-backdrop" centered v-model="showdisable" title="Voulez-vous vraiment suspendre cet utilisateur ?"
      title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showdisable = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="desactiveUser">Oui</b-button>
      </div>
    </b-modal>

    <b-modal id="modal-backdrop" centered v-model="showAddCommercial"
      title="Voulez-vous vraiment ajouter cet utilisateur au commerciaux?" title-class="font-18" hide-footer>
      <div class="row float-right mt-3">
        <b-button variant="danger" @click="showAddCommercial = false">Non</b-button>
        <b-button variant="success" class="ml-3 mr-4" @click="submitCommercialAdd">Oui</b-button>
      </div>
    </b-modal>
    <b-modal id="modal-backdrop" centered v-model="showDroitsList" title="Liste des accès" title-class="font-18"
      hide-footer :header-bg-variant="headerBgVariant" :header-text-variant="headerTextVariant">
      <div class="d-flex rounded-3 p-2 mb-2 droitContainer" style="background-color: #efefef">
        <div for="checkbox-1" class="multiselect_label" v-for="(droit, index) in listDroit" :key="index">
          {{ droit }}
        </div>
      </div>
    </b-modal>
    <b-modal id="modal-xl" size="xl" v-model="showAll" title="Extra large modal" title-class="font-18" hide-footer>
      <b-tabs nav-class="nav-tabs-custom">
        <b-tab title-link-class="p-3">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_length" class="dataTables_length">
                <label class="d-inline-flex align-items-center">
                  Afficher&nbsp;
                  <b-form-select v-model="perPage" size="sm" :options="pageOptions"></b-form-select>&nbsp;entrées
                </label>
              </div>
            </div>
            <!-- Search -->
            <div class="col-sm-12 col-md-6">
              <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                <label class="d-inline-flex align-items-center">
                  Rechercher:
                  <b-form-input v-model="filter" type="search" class="form-control form-control-sm ml-2"></b-form-input>
                </label>
              </div>
            </div>
            <!-- End search -->
          </div>
          <div class="table-responsive">
            <b-table class="table-centered" :items="activityData" :fields="activity" responsive="sm" :per-page="perPage"
              :current-page="currentPage" :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter"
              :filter-included-fields="filterOn" @filtered="onFiltered">
              <template v-slot:cell(paymentstatus)="row">
                <div class="badge font-size-12" :class="{
                  'badge-soft-danger': `${row.value}` === 'Chargeback',
                  'badge-soft-success': `${row.value}` === 'Paid',
                  'badge-soft-warning': `${row.value}` === 'Unpaid',
                }">
                  {{ row.value }}
                </div>
              </template>
              <template v-slot:cell(invoice)>
                <button class="btn btn-light btn-rounded">
                  Invoice
                  <i class="mdi mdi-download ml-2"></i>
                </button>
              </template>
              <template v-slot:cell(action)>
                <a href="javascript:void(0);" class="btn btn-success mr-3" v-b-tooltip.hover title="virement">
                  Virement
                </a>
              </template>
            </b-table>
          </div>
          <div class="row">
            <div class="col">
              <div class="dataTables_paginate paging_simple_numbers float-right">
                <ul class="pagination pagination-rounded mb-0">
                  <!-- pagination -->
                  <b-pagination v-model="currentPage" :total-rows="activityData.length"
                    :per-page="perPage"></b-pagination>
                </ul>
              </div>
            </div>
          </div>
        </b-tab>
      </b-tabs>
    </b-modal>
  </Layout>
</template>

<style>
.rights-assignment {
  font-family: Arial, sans-serif;
}

.group {
  margin-bottom: 10px;
}

.group-header {
  font-color: #3AAA35;
  font-weight: bold;
}

.group-items {
  margin-left: 20px;
  font-weight: normal !important;
}

/*.item {
  margin-top: 5px;
}*/

thead {
  background: #3aaa35;
}

thead div {
  color: #fff;
}

.input {
  right: 13px;
  position: absolute;
  display: flex;
  justify-content: center;
  width: 200px;
  height: 35px;
  bottom: 0;
  flex: 60%;
  margin-bottom: 5px;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 500;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.inputCol {
  position: relative;
}

.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #252b3b;
  border-color: #252b3b;
}

ion-icon {
  cursor: pointer;
}

.myBouton {
  background: transparent;
  text-align: center;
  outline: none;
  border: none;
}

.containerDot {
  z-index: 100;
}

.paging_simple_numbers {
  background: #3aaa35;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  padding: 10px 30px;

  box-shadow: 6px 6px 10px -1px rgba(0, 0, 0, 0.15),
    -6px -6px 10px -1px rgba(255, 255, 255, 0.7);
}

.paging_simple_numbers ul li {
  color: white !important;
}

.paging_simple_numbers ul li button {
  color: #fff !important;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: 8%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.inputCol {
  position: relative;
  box-sizing: border-box;
}

.input {
  position: relative;
  display: flex;

  width: 300px;
  height: 35px;
  top: 0;

  margin: 0;
  box-shadow: 0px 3px 6px 0px rgb(0 0 0 / 10%), 0px 1px 3px 0px rgb(0 0 0 / 8%);
  background: #ffffff;
}

.input input {
  padding: 3px 3px 3px 8px;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  font-size: 18px;
  color: #495057;
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 100;
}

.input input:focus {
  border: 3px solid #3aaa35;
}

.containerDot {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
  width: 250px;
  height: 40px;
  position: absolute;
  top: -14%;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.dots {
  position: relative;
  width: 100px;
  height: 50px;
  transform: scale(0.25, 0.25);
  margin-left: -10px;
}

.dot {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  transform: translate(0, -40%);
  opacity: 0;
  border-radius: 50%;
  background-color: hsl(0, 0%, 12%);
}

.dot-1 {
  animation: animation-dots 1.5s infinite linear 1s;
}

.dot-2 {
  animation: animation-dots 1.5s infinite linear 0.5s;
}

.dot-3 {
  animation: animation-dots 1.5s infinite linear;
}

@keyframes animation-dots {
  0% {
    left: -100px;
    opacity: 0;
  }

  20% {
    left: 0px;
    opacity: 1;
  }

  80% {
    left: 80px;
    opacity: 1;
  }

  100% {
    opacity: 0;
    left: 150px;
  }
}

.inputCol {
  position: relative;
  box-sizing: border-box;
}

.loading {
  font-size: 2rem;
  color: hsl(0, 0%, 12%);
}

.loading {
  font-weight: bold;
  font-size: 1.001rem;
  width: 100%;
  white-space: nowrap;
  color: hsl(0, 0%, 12%);
  text-align: center;
  display: inline-block;
  margin: 0 auto !important;
}

.otherThing {
  background-color: #f9edbe;
  border: 1px solid #f0c36d;
  width: 250px;
  height: 40px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -55px;
}

.btn-rmobility {
  background: #3aaa35;
  color: #ffffff;
}

.text-rmobility {
  color: #3aaa35;
}

.paging_simple_numbers ul li button {
  color: #fff !important;
}

.nav-tabs .nav-link a {
  color: rgba(44, 56, 74, 0.95);
}

.nav-tabs-custom .nav-item .nav-link.active a {
  color: #fff !important;
  font-weight: 600 !important;
  vertical-align: middle;
}




.nav-tabs-custom .nav-item .nav-link.active {
  font-weight: 600;
  background: #3aaa35 !important;
  border-bottom: 1px solid #000000 !important;
  color: #fff !important;
}

.nav-tabs-custom .nav-item .nav-link::after {
  border-bottom: 1px solid #000000 !important;
}

.checker {
  margin-top: 0.444rem;
  display: inline-flex;
  color: #4c4c4c;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  margin-left: 0.656rem;
  margin-bottom: 0;
}

.multiselect_label {
  position: relative;
  padding: 7px 34px 7px 8px;
  background: #444;
  border-radius: 4px;
  margin-right: 9px;
  margin-bottom: 10px;
  color: #fff;
  width: 8rem;
}

.droitContainer {
  display: flex;

  flex-wrap: wrap;
  align-items: center;
}

.btn-flexible {
  display: flex;

  justify-content: flex-end;
  align-self: flex-end;
}

.btn-commercial {
  color: #ffffff;
  background: #ff8c00;
}
</style>
